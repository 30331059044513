import { useEffect, useState } from "react";
import { Formik } from "formik";
import { Grid } from "@mui/material";
import http from "../../utils/axios";
import useCountries from "../../hooks/useCountries";
import OlogaButtonGroup from "../../components/buttonsx/OlogaButtonGroup";
import OlogaSubmitButton from "../../components/buttonsx/OlogaSubmitButton";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import { venueSchema } from "../../utils/formValidation";
import OlogaSelect from "../../components/select/OlogaSelect";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Box } from "@mui/material";

interface VenueFormProps {
  venue: any;
}
function VenueForm(props: any) {
  const navigate = useNavigate();
  const { countries } = useCountries();
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState();
  const getCities = async (countryId: any) => {
    setCities([]);
    if (countryId) {
      try {
        const result = await http.get(
          `/locations/countries/${Number(countryId)}/cities/names`
        );
        setCities(result.data);
      } catch (error: any) {}
    }
  };

  const handleCountryChange = (value: any) => {
    setSelectedCountry(value);
  };

  useEffect(() => {
    if (selectedCountry) {
      getCities(selectedCountry);
    } else {
      setCities([]);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (props.venue) {
      getCities(props.venue.countryId);
    }
  }, [props.venue]);
  const { closemodal } = props;
  const handleSubmit = async (values: any, { setSubmitting }: any) => {
    try {
      const url = "/venues";
      if (props.venue.id) {
        await http.put(url, values);
      } else {
        await http.post(url, values);
      }
      setSubmitting(false);
      toast.success("Venue saved successfully!");
      if (!props.venue?.fromModel) {
        navigate("/events/venues");
      }
      if (props.venue.fromModel) {
        closemodal();
      }
    } catch (error: any) {
      toast.error(error.message || "Error saving venue");
      setSubmitting(false);
    }
  };
  return (
    <Formik
      initialValues={props.venue}
      validationSchema={venueSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <OlogaTextfield name="name" label="Name" />
            </Grid>
            <Grid item xs={12} md={3}>
              <OlogaSelect
                name="countryId"
                options={countries}
                label="Country"
                setValue={handleCountryChange}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <OlogaSelect name="cityId" options={cities} label="City" />
            </Grid>
          </Grid>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6}>
              <OlogaTextfield name="address" label="Address" />
            </Grid>
            <Grid item xs={12} md={6}>
              <OlogaTextfield
                name="description"
                label="Description"
                multiline
                rows={4}
              />
            </Grid>
          </Grid>
          {props.venue.fromModel ? (
            <Box mt={2} sx={{ display: "flex", justifyContent: "flex-end" }}>
              <OlogaSubmitButton />
            </Box>
          ) : (
            <OlogaButtonGroup />
          )}
        </form>
      )}
    </Formik>
  );
}

export default VenueForm;
