import { Card, CardContent, Grid } from "@mui/material";
import { useLocation } from "react-router-dom";
import VenueForm from "./VenueForm";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";

function UpdateVenue() {
  const { state } = useLocation();

  return (
    <>
      <OlogaPageHeader
        title="Venue"
        items={[
          { label: "Events", url: "/events" },
          { label: "Venues", url: "/events/venues" },
        ]}
      />
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <VenueForm
                venue={{
                  ...state.venue,
                  fromModel: false,
                }}
              />
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default UpdateVenue;
