import styled from "@emotion/styled";
import { Formik } from "formik";
import { Box } from "@mui/material";
import { Card as MuiCard, CardContent, Grid } from "@mui/material";
import { spacing } from "@mui/system";
import http from "../../utils/axios";
import OlogaTextfield from "../../components/inputfields/OlogaTextField";
import OlogaSubmitButton from "../../components/buttonsx/OlogaSubmitButton";
import OlogaButtonGroup from "../../components/buttonsx/OlogaButtonGroup";
import { worldTourSchema } from "../../utils/formValidation";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import OlogaDatePicker from "../../components/inputfields/OlogaDatePicker";
import { formatDateToDdMmYyyy } from "../../utils/utils";

const Card = styled(MuiCard)(spacing);

interface WorldTourFormProps {
  tour?: any;
}

function WorldTourForm(props: any) {
  const navigate = useNavigate();
  const { closemodal } = props;
  const handleSubmit = async (
    values: any,
    { resetForm, setSubmitting }: any
  ) => {
    try {
      const url = "/tours/world";
      values.startDate = formatDateToDdMmYyyy(values.startDate);
      values.endDate = formatDateToDdMmYyyy(values.endDate);

      if (props?.tour?.id) {
        await http.put(url, values);
      } else {
        await http.post(url, values);
      }

      resetForm();
      setSubmitting(false);
      toast.success("Tour saved successfully!");
      if (!props.tour?.fromModel) {
        navigate("/events/world-tours");
      }
      closemodal();
    } catch (error: any) {
      setSubmitting(false);
      toast.error(error.message || "Error saving vendor");
    }
  };

  return (
    <Formik
      initialValues={props?.tour}
      validationSchema={worldTourSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) => (
        <Card>
          <CardContent>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <OlogaTextfield name="name" label="Name" />
                </Grid>
                <Grid item xs={12} md={6}>
                  <OlogaTextfield name="artist" label="Artist" />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <OlogaDatePicker
                    name="startDate"
                    label="Start Date"
                    disablePast={true}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <OlogaDatePicker
                    name="endDate"
                    label="End Date"
                    disablePast={true}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item xs={12} md={12}>
                  <OlogaTextfield
                    name="description"
                    label="Description"
                    multiline
                    rows={4}
                  />
                </Grid>
              </Grid>
              {props.tour.fromModel ? (
                <Box
                  mt={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <OlogaSubmitButton />
                </Box>
              ) : (
                <OlogaButtonGroup />
              )}
            </form>
          </CardContent>
        </Card>
      )}
    </Formik>
  );
}

export default WorldTourForm;
