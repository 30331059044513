import styled from "@emotion/styled";

import { Card as MuiCard, Grid, Autocomplete, TextField } from "@mui/material";
import { GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import { spacing } from "@mui/system";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import { useNavigate } from "react-router";
import useLookups from "../../hooks/useLookups";
import { useState } from "react";

const Card = styled(MuiCard)(spacing);

function Orders() {
  const navigate = useNavigate();
  const { data: events } = useLookups("/events/names");
  const [eventId, setEventId] = useState(0);
  const [url, setUrl] = useState("");

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: "Order #",
      flex: 0.3,
      editable: false,
    },
    {
      field: "event",
      headerName: "Event",
      flex: 1,
      editable: false,
    },
    {
      field: "clientName",
      headerName: "Client",
      flex: 1,
      editable: false,
    },
    {
      field: "clientContact",
      headerName: "Phone Number",
      flex: 1,
      editable: false,
    },
    {
      field: "transactionDate",
      headerName: "Order Date",
      flex: 1,
      editable: false,
    },
    {
      field: "amount",
      headerName: "Amount",
      flex: 1,
      editable: false,
    },
    {
      field: "shippingCost",
      headerName: "Shipping Cost",
      flex: 1,
      editable: false,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      editable: false,
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.5,
      editable: false,
      type: "actions",
      getActions: (params) => [
        <GridActionsCellItem
          icon={<VisibilityOutlinedIcon />}
          onClick={() => {
            navigate("/finance/orderdetail", {
              state: {
                id: params.id,
              },
            });
          }}
          label="Details"
        />,
      ],
    },
  ];
  return (
    <>
      <OlogaPageHeader title="Re-Orders" />
      <Grid justifyContent="space-between" container spacing={6} mb={4}>
        <Grid item xs={12} md={6}></Grid>
        <Grid item xs={12} md={6}>
          <Autocomplete
            options={events}
            getOptionLabel={(option: any) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Select the event"
                variant="outlined"
              />
            )}
            onChange={(event, value) => {
              setEventId(value?.id);
              setUrl(value?.id ? `/orders/search/q?eventId=${value?.id}` : "");
            }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <OlogaDataGrid
              url={url}
              columns={columns}
              exportToExcel={true}
              fileName="Orders"
              excludeFromExport={["actions"]}
            />
          </Card>
        </Grid>
      </Grid>
    </>
  );
}

export default Orders;
