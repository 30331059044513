import {
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  CardContent as MuiCardContent,
  Divider as MuiDivider,
  Paper as MuiPaper,
  Grid,
  Typography,
  TextField,
  styled,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { spacing } from "@mui/system";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import SubmitButton from "../../components/buttonsx/SubmitButton";
import CancelButton from "../../components/buttonsx/CancelButton";
import OlogaGenericCombo from "../../components/combosx/OlogaGenericCombo";
import Item from "../../components/combosx/Item";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import useAuth from "../../hooks/useAuth";
import { Description } from "@mui/icons-material";
import { size } from "polished";
import useLookups from "../../hooks/useLookups";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { OlogaButton } from "../../components/buttonsx/OlogaButton";
import { Formik } from "formik";
import OlogaAutocomplete from "../../components/select/OlogaAutocomplete";

const Card = styled(MuiCard)(spacing);
const CardContent = styled(MuiCardContent)(spacing);
const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

const EventStationProductBatchEdit: React.FC<any> = ({
  mode,
  url,
  successMessage,
  onSucessMessage,
}) => {
  const initialFormData = {
    stationVendor: null,
    stationEvent: null,
    originStation: null,
    targetStation: null,
    destinationStation: null,
  };
  const { data: vendors } = useLookups("/companies/vendors/user");
  const [formData, setFormData] = useState(initialFormData);

  const [formMode] = useState(mode);
  const [showSuccess, setShowSuccess] = useState(false);
  const [fromEvents, setFromEvents] = useState([]);
  const [targetEvents, setTargetEvents] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [productBrands, setProductBrands] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const { user } = useAuth();
  const [vendorEvents, setVendorEvents] = useState([]);
  const [stations, setStations] = useState([]);
  const [station, setStation] = useState({ id: "", name: "" });
  const [stationId, setStationId] = useState("");
  const [stationOrigin, setStationOrigin] = useState({ id: "", name: "" });
  const [eventId, setEventId] = useState("");
  const [vendorId, setVendorId] = useState("");
  const [stationName, setStationName] = useState({});
  const [stationDestinations, setStationDestinations] = useState([]);
  const [vendor, setVendor] = useState({
    id: null,
    value: null,
    name: null,
  });
  const [stationsBatch, setStationsBatch] = useState<{ [key: string]: [] }>({});
  const [eventProductBatch, setEventProductBatch] = useState({
    brand: "",
    fromEvent: {
      id: null,
      value: null,
      name: null,
    },
    targetEvent: {
      id: null,
      value: null,
      name: null,
    },
    imageUri: "",
  });
  const [open, setOpen] = React.useState(false);
  const initialSelectProd = {
    id: null,
    selected: null,
    productDescription: null,
    price: null,
    cost: null,
    quantity: null,
    imageUri: null,
    sizes: [],
    eventId: "",
    vendorId: null,
    ProductId: null,
    productId: null,
    originStationId: "",
    destinyStationId: "",
  };
  const [selectedProd, setSelectedProd] = useState(initialSelectProd);
  const [showBtnEdit, setShowBtnEdit] = useState(false);
  const [stationDestinationsProd, setStationDestinationsProd] = React.useState<
    any[]
  >([]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const getVendorEvents = async (vendorId: string) => {
    setVendorEvents([]);
    if (vendorId) {
      try {
        const response = await axios.get(`/events/names`);
        setVendorEvents(response.data);
      } catch (error) {}
    }
  };
  const handleChangeVendor = async (e: Item) => {
    // setStationAllocations([]);
    setVendor(e as any);
    updateVariable(formData, "stationVendor", e.id);
    updateVariable(eventProductBatch, "stationVendor", e.id);
    getVendorEvents(e.id.toString());
  };
  const handleChangeEvent = async (e: Item) => {
    updateVariable(formData, "stationEvent", e);
    getStations(e.id.toString(), formData?.stationVendor as any);
  };
  const getStations = async (eventId: string, vendorId: string) => {
    setStations([]);
    // console.log("eventId", eventId);
    // console.log("vendorId", vendorId);
    if (eventId && vendorId) {
      try {
        const response = await axios.get(
          `/stations/event/${eventId}/vendor/${vendorId}/names`
        );
        console.log("RESPONSE", response);
        setStations(response.data);
        return response.data as [];
      } catch (error) {}
    }
  };

  const navigate = useNavigate();
  const params = useParams();

  const retrieveAllProducts = async (
    originAction: string,
    actionObjectId: string
  ) => {
    const response =
      originAction === "brand"
        ? await axios.get(
            "/eventProduct/brand/" +
              actionObjectId +
              "/event/" +
              (eventProductBatch.fromEvent as any).id
          )
        : await axios.get("eventProduct/event/" + actionObjectId);

    setAllProducts(
      response.data.map((r: any) => ({
        id: eventProductBatch.fromEvent.id === "" ? "" : r.id,
        selected: "off",
        productId: r.productId,
        productDescription:
          eventProductBatch.fromEvent.id === ""
            ? r.description
            : r.productDescription,
        price: r.price,
        cost: r.cost,
        quantity: r.quantity,
        imageUri: r.imageUri,
        sizes: r.sizes,
      }))
    );
  };

  const retriveFromEvents = async () => {
    const response = await axios.get(`/events/names`);
    setFromEvents(
      response.data.map((r: Item) => {
        return {
          id: r.id,
          description: r.name,
          name: "fromEvent",
        };
      })
    );
  };

  const retriveTargetEvents = async () => {
    const response = await axios.get(`/events/names`);
    setTargetEvents(
      response.data.map((r: Item) => {
        return {
          id: r.id,
          description: r.name,
          name: "targetEvent",
        };
      })
    );
  };

  const retriveProductBrands = async () => {
    const response = await axios.get("/simple-entity/product-brands/vendor");
    setProductBrands(
      response.data.map((r: Item) => {
        return {
          id: r.id,
          description: r.description,
          name: "brand",
        };
      })
    );
  };
  const handleOriginStationChange = async (e: Item) => {
    updateVariable(formData, "targetStation", e);
    setStation({ id: e.id as unknown as string, name: e.name });
    // console.log("STATIONID", e);
    setStationName(e.name);
    setStationId(e.id.toString());
    setStationDestinations(stations.filter((r: any) => r.id !== e.id));
    setShowBtnEdit(true);
  };
  const onSubmit = async (data: any) => {
    // console.log("STATION BATCH", stationsBatch);
    // console.log("STATION DESTINATION", stationDestinationsProd);

    try {
      setShowLoading(true);

      const response = await axios.post(
        `stock/transfer`,
        stationDestinationsProd
      );

      setShowLoading(false);
      toast.success("Products replicated sucessfully");
      setShowSuccess(true);
      navigate("/products/search", { replace: true });
    } catch (error) {
      toast.error((error as any).message);
    }
  };

  const setFormFields = async (event: Item) => {
    updateVariable(eventProductBatch, event.name, event);
    updateVariable(formData, event.name, event);

    if (event.name === "fromEvent") {
      const responseStation = await axios.get(
        `/stations/event/${event.id.toString()}/vendor/${vendor?.id}/names`
      );
      setStations(responseStation.data);
      setEventId(event.id.toString());
      // console.log(responseStation.data);
      retrieveAllProducts("fromEvent", event.id.toString());
      const response = await axios.get(
        "eventProduct/event/" + event.id.toString()
      );

      const prods = response.data.map((r: any) => ({
        id: eventProductBatch.fromEvent.id === "" ? "" : r.id,
        selected: "off",
        productId: r.productId,
        productDescription:
          eventProductBatch.fromEvent.id === ""
            ? r.description
            : r.productDescription,
        price: r.price,
        cost: r.cost,
        quantity: r.quantity,
        imageUri: r.imageUri,
        sizes: r.sizes,
      }));
      var obj: any = {};
      responseStation.data.map((station: any) => {
        if (station.name === "WAREHOUSE") {
          setStationOrigin({ id: station.id, name: station.name });
        }

        if (station.name != "WAREHOUSE") {
          obj[`${station.name}`] = prods;
        }
      });
      console.log("RESULT OBJ", obj);
      setStationsBatch(obj);
    }
    if (event.name === "brand") {
      retrieveAllProducts("brand", event.id.toString());
    }
  };
  const setTableFields = (e: any) => {
    const editedProducts = [] as any;

    allProducts.map((item: any) => {
      if (e.target.id === "s-" + item.id || e.target.id === "s-all") {
        item[e.target.name] = item[e.target.name] === "on" ? "off" : "on";
      }
      if (e.target.id === "q-" + item.id) {
        setSelectedProd({ ...selectedProd, quantity: e.target.value });

        item[e.target.name] = e.target.value;
        var obj: any = {};
        var newItem: any = [];
      }
      if (e.target.id === "p-" + item.id) {
        item[e.target.name] = e.target.value;
        setSelectedProd({ ...selectedProd, price: e.target.value });
      }
      if (e.target.id === "c-" + item.id) {
        item[e.target.name] = e.target.value;
        setSelectedProd({ ...selectedProd, cost: e.target.value });
      }
      if (e.target.id.includes("qs-" + item.id)) {
        item.sizes.map((sizeItem: any) => {
          if ("qs-" + item.id + "-" + sizeItem.sizeId === e.target.id) {
            sizeItem["quantity"] = e.target.value;
          }
        });
      }
      editedProducts.push(item);
    });

    setAllProducts(editedProducts as any);
  };

  useEffect(() => {
    retriveFromEvents();
    retriveTargetEvents();
    retriveProductBrands();
    setShowSuccess(false);
  }, [formMode]);

  const updateVariable = (object: any, propertyName: string, newValue: any) => {
    const propertyPath = propertyName.split(".");
    let currentObject: any = { ...object };

    if (propertyPath.length > 1) {
      for (let i = 0; i < propertyPath.length - 1; i++) {
        currentObject = currentObject[propertyPath[i]];
      }
      currentObject[propertyPath[propertyPath.length - 1]] = newValue;
      setFormData({ ...object });
      setEventProductBatch({ ...object });
    } else if (propertyPath.length == 1) {
      currentObject[propertyName] = newValue;
      setFormData({ ...object });
      setEventProductBatch({ ...currentObject });
    }
  };

  return (
    <React.Fragment>
      <OlogaPageHeader
        title="Station Allocations"
        items={[{ label: "Products", url: "/products/search" }]}
      />
      <Card mb={12}>
        <CardContent>
          <Formik initialValues={fromEvents} onSubmit={onSubmit}>
            {({
              handleSubmit,
              values,
              status,
              errors,
              touched,
              setFieldValue,
            }) => (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2} marginBottom={3}>
                  <Grid item xs={6}>
                    {/* <OlogaGenericCombo
                      name="stationVendor"
                      label="Vendors"
                      items={vendors}
                      value={vendor || ""}
                      onChange={handleChangeVendor}
                    /> */}

                    <OlogaAutocomplete
                      name="stationVendor"
                      options={vendors}
                      label="vendors"
                      initValue={vendor.name || ""}
                      handleChange={handleChangeVendor}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <OlogaGenericCombo
                      label="Event"
                      name="fromEvent"
                      items={fromEvents}
                      value={eventProductBatch.fromEvent || ""}
                      onChange={setFormFields}
                    />
                  </Grid>
                </Grid>

                <br />
                <br />

                <TableContainer>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            id={"s-all"}
                            name="selected"
                            size="small"
                            onChange={(e) => setTableFields(e)}
                          />
                        </TableCell>
                        <TableCell>Image</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Price</TableCell>
                        <TableCell>Cost</TableCell>
                        <TableCell>Stock</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {allProducts.map(
                        ({
                          id,
                          selected,
                          productDescription,
                          price,
                          cost,
                          quantity,
                          imageUri,
                          sizes,
                        }) => (
                          <TableRow key={id + productDescription + price}>
                            <TableCell>
                              <Checkbox
                                id={"s-" + id}
                                name="selected"
                                size="small"
                                onChange={(e) => setTableFields(e)}
                                checked={selected === "on"}
                              />
                            </TableCell>
                            <TableCell width={30}>
                              <img
                                src={imageUri}
                                width="70px"
                                height="auto"
                                alt="product-img"
                              />
                            </TableCell>
                            <TableCell>{productDescription}</TableCell>
                            <TableCell>
                              <TextField
                                id={"p-" + id}
                                name="price"
                                value={price || ""}
                                onChange={(e) => setTableFields(e)}
                                disabled
                              />
                            </TableCell>
                            <TableCell>
                              <TextField
                                id={"c-" + id}
                                name="cost"
                                value={cost || ""}
                                onChange={(e) => setTableFields(e)}
                                disabled
                              />
                            </TableCell>
                            <TableCell>
                              {(sizes as []).length == 0 && (
                                <TextField
                                  id={"q-" + id}
                                  name="quantity"
                                  label="Quantity"
                                  value={quantity || ""}
                                  onChange={(e) => setTableFields(e)}
                                  disabled
                                />
                              )}
                              {(sizes as []).length > 0 &&
                                (sizes as []).map(
                                  ({ sizeId, description, quantity }) => (
                                    <Grid item xs={12} marginBottom={3}>
                                      <TextField
                                        id={"qs-" + id + "-" + sizeId}
                                        name="sizes"
                                        label={description + " - Quantity"}
                                        value={quantity || ""}
                                        onChange={(e) => setTableFields(e)}
                                        disabled
                                      />
                                    </Grid>
                                  )
                                )}
                            </TableCell>
                            <TableCell>
                              <OlogaButton
                                label={"Set to Station"}
                                onClick={(e) => {
                                  console.log("CLIQ", e);
                                  const editProd = {
                                    id,
                                    selected,
                                    productDescription,
                                    price,
                                    cost,
                                    quantity,
                                    imageUri,
                                    sizes,
                                    eventId: eventId,
                                    vendorId: vendor?.id,
                                    ProductId: id,
                                    productId: id,
                                    originStationId: stationOrigin.id,
                                    destinyStationId: "",
                                  };
                                  console.log(editProd);
                                  handleClickOpen();
                                  setSelectedProd(editProd);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">
                      {"Set The Price for Each Station"}
                    </DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Select the Station and set the value and save for the
                        station needed
                      </DialogContentText>
                      <br />

                      <>
                        <Grid container spacing={2} marginBottom={3}>
                          <Grid item xs={12}>
                            <OlogaGenericCombo
                              name="Station"
                              label="Target Station"
                              items={stations}
                              value={station || ""}
                              onChange={handleOriginStationChange}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            {selectedProd.productDescription}
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id={"p-" + selectedProd.id}
                              name="price"
                              value={selectedProd.price || ""}
                              onChange={(e) => setTableFields(e)}
                              disabled
                            />{" "}
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              id={"c-" + selectedProd.id}
                              name="cost"
                              value={selectedProd.cost || ""}
                              onChange={(e) => setTableFields(e)}
                              disabled
                            />{" "}
                          </Grid>
                          <Grid item xs={6}>
                            {(selectedProd.sizes as []).length == 0 && (
                              <TextField
                                id={"q-" + selectedProd.id}
                                name="quantity"
                                label="Quantity"
                                value={selectedProd.quantity || ""}
                                onChange={(e) => setTableFields(e)}
                                required
                              />
                            )}
                          </Grid>
                        </Grid>
                      </>
                    </DialogContent>
                    <DialogActions>
                      {showBtnEdit ? (
                        <OlogaButton
                          label={`Save in ${stationName}`}
                          onClick={(e) => {
                            console.log("SELECTEDPROD", selectedProd);
                            console.log("STATIONID", station.id);
                            selectedProd.destinyStationId = station.id;
                            setStationDestinationsProd((current) => [
                              ...current,
                              selectedProd,
                            ]);
                            setSelectedProd({
                              ...selectedProd,
                              destinyStationId: station.id,
                            });
                            // handleClose();
                            setShowBtnEdit(false);
                            const editedStationBatch = stationsBatch;
                            let foundProd = [] as any;
                            foundProd = stationsBatch[`${stationName}`];
                            foundProd.push(selectedProd);
                            console.log("EDITEDPROD", editedStationBatch);
                            console.log("FOUNDPROD", foundProd);

                            setStationsBatch({
                              ...stationsBatch,
                              stationName: foundProd,
                            });
                            console.log("STATIONBATCH", stationsBatch);
                          }}
                        />
                      ) : (
                        ""
                      )}

                      <OlogaButton
                        label={"Close"}
                        onClick={(e) => {
                          setSelectedProd(initialSelectProd);
                          handleClose();
                        }}
                      />
                    </DialogActions>
                  </Dialog>
                </TableContainer>
                {/* </AccordionDetails>
              </Accordion>
            ))} */}

                <br />
                <Grid
                  container
                  marginTop={5}
                  spacing={3}
                  mt={2}
                  sx={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Grid item marginLeft={2} className="form-buttons">
                    <SubmitButton label={"Save"} onClick={onSubmit} />
                  </Grid>
                  <Grid item marginRight={2}>
                    <CancelButton navigateTo={"../search"} />
                  </Grid>
                </Grid>
              </form>
            )}
          </Formik>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default EventStationProductBatchEdit;
