import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import SubmitButton from "../../components/buttonsx/SubmitButton";
import Item from "../../components/combosx/Item";
import StockProduct from "./StockProduct";
import OlogaGenericCombo from "../../components/combosx/OlogaGenericCombo";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import axios from "../../utils/axios";
import { toast } from "react-toastify";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import useLookups from "../../hooks/useLookups";
import useAuth from "../../hooks/useAuth";
import OlogaLoading from "../../components/misc/OlogaLoading";

const eventProductHeaders = [
  { header: "ID", key: "id" },
  { header: "Imagem", key: "imageUri" },
  { header: "Descrição do Produto", key: "productDescription" },
  { header: "Origin Station Quantity", key: "originQuantity" },
  { header: "Destination Station Quantity", key: "destinationQuantity" },
  { header: "Quantity", key: "quantity" },
];

const StockTransferPage: React.FC<any> = ({
  mode,
  url,
  successMessage,
  onSucessMessage,
}) => {
  const initialFormData = {
    stationVendor: null,
    stationEvent: null,
    originStation: null,
    destinationStation: null,
    productSize: null,
  };

  const { data: vendors } = useLookups("/companies/vendors/user");
  const [vendorEvents, setVendorEvents] = useState([]);
  const [stations, setStations] = useState([]);
  const [stationDestinations, setStationDestinations] = useState([]);
  const [eventProducts, setEventProducts] = useState([]);
  const [productSizes, setProductSizes] = useState([]);
  const [formData, setFormData] = useState(initialFormData);
  const [showLoading, setShowLoading] = useState(false);
  const { user: userContext } = useAuth();

  useEffect(() => {
    if (userContext?.roleName != "ROLE_ADMIN") {
      getVendorEvents(userContext?.vendorId);
    }
  }, [userContext]);

  const getProductSizes = async () => {
    const response = await axios.get("/simple-entity/product-sizes");
    try {
      setProductSizes(response.data);
    } catch (error) {}

    const item = { id: 0, description: "N/A" };

    (productSizes as any).push(item as any);
  };

  const getVendorEvents = async (vendorId: string) => {
    setVendorEvents([]);
    if (vendorId) {
      try {
        const response = await axios.get(`/events/vendorx/${vendorId}`);
        setVendorEvents(response.data);
      } catch (error) {}
    }
  };

  const getStations = async (eventId: string, vendorId: string) => {
    setStations([]);
    if (eventId && vendorId) {
      try {
        const response = await axios.get(
          `/stations/event/${eventId}/vendor/${vendorId}/names`
        );
        setStations(response.data);
      } catch (error) {}
    }
  };

  const getEventAndVendorProducts = async (
    eventId: string,
    vendorId: string,
    originStationId: string,
    destinationStationId: string
  ) => {
    const response = await axios.get(
      `/stock/event/${eventId}/vendor/${vendorId}/originStation/${originStationId}/destinationStation/${destinationStationId}`
    );
    setEventProducts(
      response.data.map((r: StockProduct) => {
        return {
          id: eventId === "" ? "" : r.id,
          price: r.price,
          productDescription: r.name,
          originQuantity: r.originQuantity,
          destinationQuantity: r.destinationQuantity,
          sizeDescription: r.sizeDescription,
          sizeId: r.sizeId,
          imageUri: r.imageUri,
          quantity: 0,
        };
      })
    );
  };

  const handleChangeVendor = async (e: Item) => {
    updateVariable(formData, "stationVendor", e);
    getVendorEvents(e.id.toString());
  };

  const handleChangeEvent = async (e: Item) => {
    updateVariable(formData, "stationEvent", e);
    getStations(
      e.id.toString(),
      formData?.stationVendor
        ? (formData?.stationVendor as any).id
        : userContext?.vendorId
    );
    getProductSizes();
  };

  const handleOriginStationChange = async (e: Item) => {
    updateVariable(formData, "originStation", e);
    setStationDestinations(stations.filter((r: any) => r.id !== e.id));
  };

  const handleDestinationStationChange = async (e: Item) => {
    updateVariable(formData, "destinationStation", e);
    getEventAndVendorProducts(
      (formData?.stationEvent as any).id,
      formData?.stationVendor
        ? (formData?.stationVendor as any).id
        : userContext?.vendorId,
      (formData?.originStation as any).id,
      e.id.toString()
    );
  };

  const setTableFields = (e: any) => {
    const editedProducts = eventProducts.map((item: any) =>
      item.id + (item.sizeDescription || "") === e.target.id && e.target.name
        ? { ...item, [e.target.name]: e.target.value }
        : item
    );
    setEventProducts(editedProducts as any);
  };

  const onSubmit = async () => {
    setShowLoading(true);
    try {
      const transfers = [];
      for (const item of eventProducts) {
        if ((item as any).quantity > 0) {
          const dto = {
            eventId: (formData?.stationEvent as any).id,
            vendorId: formData?.stationVendor
              ? (formData?.stationVendor as any).id
              : userContext?.vendorId,
            eventProductId: (item as any).id,
            originStationId: (formData?.originStation as any).id,
            destinyStationId: (formData?.destinationStation as any).id,
            productSizeId: (item as any).sizeId,
            quantity: (item as any).quantity,
          };

          if ((item as any).quantity > (item as any).originQuantity) {
            toast.error(
              `There's isn't available stock for this transfer. Actual stock unit [${
                (item as any).originQuantity
              }], stock to transfer [${(item as any).quantity}].`
            );
            return;
          }

          transfers.push(dto as any);
        }
      }

      const transfersArray = [];
      for (const [key, value] of Object.entries(transfers)) {
        transfersArray.push(value);
      }

      const response = await axios.post("/stock/transfer", transfersArray);

      toast.success("Stock transfer made sucessfully");

      getEventAndVendorProducts(
        (formData?.stationEvent as any).id,
        formData?.stationVendor
          ? (formData?.stationVendor as any).id
          : userContext?.vendorId,
        (formData?.originStation as any).id,
        (formData?.destinationStation as any).id
      );
    } catch (error) {
      toast.error((error as any).message);
    }
    setShowLoading(false);
  };

  const updateVariable = (object: any, propertyName: string, newValue: any) => {
    const propertyPath = propertyName.split(".");
    let currentObject: any = { ...object };

    if (propertyPath.length > 1) {
      for (let i = 0; i < propertyPath.length - 1; i++) {
        currentObject = currentObject[propertyPath[i]];
      }
      currentObject[propertyPath[propertyPath.length - 1]] = newValue;
      setFormData({ ...object });
    } else if (propertyPath.length == 1) {
      currentObject[propertyName] = newValue;
      setFormData({ ...currentObject });
    }
  };

  return (
    <React.Fragment>
      <OlogaPageHeader
        title="Stock Transfers"
        items={[{ label: "Products", url: "/products/search" }]}
      />
      <Card>
        <OlogaLoading open={showLoading} />
        <CardContent>
          <form onSubmit={onSubmit}>
            <Grid container spacing={2} marginBottom={3}>
              {userContext?.roleName == "ROLE_ADMIN" && (
                <Grid item xs={6}>
                  <OlogaGenericCombo
                    name="stationVendor"
                    label="Vendors"
                    items={vendors}
                    value={formData.stationVendor || ""}
                    onChange={handleChangeVendor}
                  />
                </Grid>
              )}
              <Grid item xs={6}>
                <OlogaGenericCombo
                  name="stationEvent"
                  label="Events"
                  items={vendorEvents}
                  value={formData.stationEvent || ""}
                  onChange={handleChangeEvent}
                />
              </Grid>
              <Grid item xs={6}>
                <OlogaGenericCombo
                  name="originStation"
                  label="Origin Station"
                  items={stations}
                  value={formData.originStation || ""}
                  onChange={handleOriginStationChange}
                />
              </Grid>
              <Grid item xs={6}>
                <OlogaGenericCombo
                  name="destinationStation"
                  label="Destination Station"
                  items={stationDestinations}
                  value={formData.destinationStation || ""}
                  onChange={handleDestinationStationChange}
                />
              </Grid>
            </Grid>
            <br />
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Image</TableCell>
                    <TableCell>Product Name</TableCell>
                    <TableCell>Size Description</TableCell>
                    <TableCell>Origin Quantity</TableCell>
                    <TableCell>Destiny Quantity</TableCell>
                    <TableCell>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {eventProducts.map(
                    ({
                      id,
                      imageUri,
                      productDescription,
                      sizeDescription,
                      sizeId,
                      originQuantity,
                      destinationQuantity,
                      quantity,
                    }) => (
                      <TableRow key={id + "" + (sizeId || "")}>
                        <TableCell width={30}>
                          <img
                            src={imageUri}
                            width="70px"
                            height="auto"
                            alt="product-img"
                          />
                        </TableCell>
                        <TableCell>{productDescription}</TableCell>
                        <TableCell>{sizeDescription || "N/A"}</TableCell>
                        <TableCell>{originQuantity}</TableCell>
                        <TableCell>{destinationQuantity || "0"}</TableCell>
                        <TableCell>
                          <TextField
                            id={id + "" + (sizeDescription || "")}
                            name={"quantity"}
                            value={quantity || ""}
                            label={"Quantity to Transfer"}
                            onChange={setTableFields}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <br />
            <Grid
              container
              marginTop={5}
              spacing={3}
              mt={2}
              sx={{ display: "flex", justifyContent: "flex-end" }}
            >
              {" "}
              <Grid item marginLeft={2} className="form-buttons">
                <SubmitButton label={"Save"} onClick={onSubmit} />
              </Grid>
            </Grid>
          </form>
        </CardContent>
      </Card>
    </React.Fragment>
  );
};

export default StockTransferPage;
