import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import OlogaPageHeader from "../../components/misc/OlogaPageHeader";
import {
  Card as MuiCard,
  CardContent,
  Tab,
  Tabs,
  Grid,
  Box,
} from "@mui/material";
import OlogaTabPanel from "../../components/misc/OlogaTab";
import StationForm from "../station/StationForm";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import IntegrationProviderEndpoints from "./IntegrationProviderEnpoints";
import ValueAddedServices from "./ValueAddedServices";
import ValueAddedServicesProducts from "./ValueAddedServicesProducts";

const Card = styled(MuiCard)(spacing);

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const IntegrationProviderDetailsPage = () => {
  const { state } = useLocation();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <React.Fragment>
      <OlogaPageHeader
        title={state.provider.name + " - " + state.provider.type}
        buttonLabel={""}
        onClick={() => {
          //setOpen(true);
        }}
      />
      <Grid container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Endpoint configuration" {...a11yProps(0)} />
                    <Tab label="VAS Products" {...a11yProps(1)} />
                  </Tabs>
                </Box>
                <OlogaTabPanel value={value} index={0}>
                  <IntegrationProviderEndpoints
                    providerId={state.provider.providerId}
                  />
                </OlogaTabPanel>
                <OlogaTabPanel value={value} index={1}>
                  <ValueAddedServicesProducts
                    providerId={state.provider.providerId}
                  />
                </OlogaTabPanel>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default IntegrationProviderDetailsPage;
