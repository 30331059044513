import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import SettingsForm from "./SettingsForm";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import React, { useState, useRef, useEffect } from "react";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Stack from "@mui/material/Stack";
import { toast } from "react-toastify";
import Grid from "@mui/material/Grid";
import http from "../../utils/axios";
import SubmitButton from "../../components/buttonsx/SubmitButton";
import SettingsList from "../settings/SettingsList";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  maxHeight: "90%",
  overflowY: "auto", // for auto scroll into Y, if Y  height bee more than 90%
  bgcolor: "background.paper",
  border: "2px solid #bb86fc",
  boxShadow: 24,
  p: 4,
};

const btnStyl = {
  height: "50px",
  textAlign: "right",
};

export default function SettingsModal(props: any) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    onClosemodal();
    setOpen(false);
  };
  const { onClosemodal } = props;
  const handleDelete = async (id: any) => {
    const url = "/simple-entity/delete/" + id;
    if (id) {
      let result = await http.post(url);
      if (result?.data == "sucessfully") {
        handleClose();
        toast.success("Setting deleted sucessfully");
      } else {
        toast.error("Error Deleting Setting");
      }
    }
  };
  useEffect(() => {}, []);
  const styleStyle = {
    marginTop: "10px",
    marginBottom: "10px",
  };
  return (
    <div>
      {props.btnType === "DeleteIcon" ? (
        <GridActionsCellItem
          icon={<DeleteIcon />}
          onClick={() => {
            handleOpen();
          }}
          label="Edit"
        />
      ) : (
        <Button
          sx={btnStyl}
          onClick={handleOpen}
          variant="outlined"
          startIcon={props.btnIcon}
        >
          {props.btnName}
        </Button>
      )}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {props.title}
          </Typography>
          <hr />
          {props.btnType === "DeleteIcon" ? (
            <>
              <Stack sx={{ width: "100%" }} spacing={2}>
                <Alert severity="warning">
                  <AlertTitle>Warning</AlertTitle>
                  Are you sure you want to delete this Setting? This action
                  cannot be
                  <strong> undone </strong>
                </Alert>
              </Stack>
              <Grid
                container
                rowSpacing={1}
                columnSpacing={{ xs: 1, sm: 2, md: 3 }}
              >
                <Grid item xs={12}>
                  <p>Type: {props?.settingObj?.row?.typeDto}</p>
                  <p>Description: {props?.settingObj?.row?.description}</p>
                </Grid>
                <Grid item xs={6}>
                  <Grid item className="form-buttons">
                    <SubmitButton
                      label={"Delete"}
                      onClick={() => {
                        handleDelete(props?.settingObj?.row?.id);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : props.btnType === "ListSetting" ? (
            <SettingsList renderFormat={props.listSettingRender} />
          ) : (
            <SettingsForm
              type={props.type}
              parentId={props.parentId}
              closemodal={handleClose}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}
