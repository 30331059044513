import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { NavLink, useNavigate, useParams, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import {
  CardContent,
  Grid,
  Link,
  Breadcrumbs as MuiBreadcrumbs,
  Card as MuiCard,
  Divider as MuiDivider,
  Typography,
  Paper as MuiPaper,
  Button,
} from "@mui/material";
import { GridColDef, GridActionsCellItem, GridRowId } from "@mui/x-data-grid";
import { spacing } from "@mui/system";
import OlogaDataTableItems from "../../components/tables/OlogaDataTableItems";
import http from "../../utils/axios";
import OlogaDataGrid from "../../components/tables/OlogaDataGrid";

const Card = styled(MuiCard)(spacing);

const Divider = styled(MuiDivider)(spacing);

const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);

const Paper = styled(MuiPaper)(spacing);

function EmptyCard() {
  return (
    <Card mb={6}>
      <CardContent>
        <Typography variant="h6" gutterBottom>
          No Data Available
        </Typography>
        <Typography variant="body2" gutterBottom>
          No Data Available
        </Typography>
      </CardContent>
    </Card>
  );
}

function TransactionExportErrorList() {
  const [items, setItems] = useState([]);
  const [url, setUrl] = useState("");
  const { state } = useLocation();
  const navigate = useNavigate();

  const columns: GridColDef[] = [
    {
      field: "txId",
      headerName: "ID",
      flex: 0.6,
      editable: false,
    },
    {
      field: "totalAmount",
      headerName: "TOTAL AMOUNT",
      flex: 0.5,
      editable: false,
    },
    {
      field: "transactionDate",
      headerName: "TRANSACTION DATE",
      flex: 0.6,
      editable: false,
    },
    {
      field: "uploadDate",
      headerName: "IQ SYNC DATE",
      flex: 0.6,
      editable: false,
    },
    {
      field: "syncMessage",
      headerName: "SYNC MESSAGE",
      flex: 1,
      editable: false,
    },
  ];

  const getData = async () => {
    setUrl(
      `/transactions/search/q?vendor.id=${state.vendorId}&event.id=${state.eventId}&status=0&syncStatus=-2`
    );
  };

  useEffect(() => {
    getData();
  }, []);
  function EventDetailInfo() {
    return (
      <Card mb={6}>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Event :
          </Typography>
        </CardContent>
      </Card>
    );
  }
  return (
    <React.Fragment>
      <Helmet title="Blank" />
      <Typography variant="h3" gutterBottom display="inline">
        Transaction Export Error List
      </Typography>

      <Breadcrumbs aria-label="Breadcrumb" mt={2}>
        <Link component={NavLink} to="/">
          Dashboard
        </Link>
        <Link
          onClick={() => {
            navigate(state.origin, {
              state: {
                eventId: state.eventId,
                vendorId: state.vendorId,
              },
            });
          }}
        >
          Transaction Export
        </Link>
        <Typography>Transaction Export Error List</Typography>
      </Breadcrumbs>
      {/* <EventDetailInfo /> */}

      <Divider my={6} />

      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Card mb={6}>
            <Paper>
              <OlogaDataGrid
                url={url}
                exportToExcel={true}
                fileName="ImportErrors"
                excludeFromExport={["actions"]}
                columns={columns}
              />
            </Paper>
          </Card>
        </Grid>
        <Grid
          container
          marginTop={5}
          spacing={3}
          mt={2}
          sx={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Grid item marginRight={2}>
            <Button
              onClick={() => {
                navigate(state.origin, {
                  state: {
                    eventId: state.eventId,
                    vendorId: state.vendorId,
                  },
                });
              }}
              name="Detail"
              variant="outlined"
            >
              Back
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}

export default TransactionExportErrorList;
